import logo from './logo.svg';
import './App.css';
import {useEffect} from "react";
function App() {
    const check = document.getElementById("navi-toggle");
    const uncheckNav= async()=>{
        check.checked = false;
    }
    useEffect(() => {
        document.title = "Himalayan Incense";
       

      }, [])
  return (<div>
    <div className="navigation">
    <input type="checkbox" className="navigation__checkbox" id="navi-toggle"></input>

    <label htmlFor="navi-toggle" className="navigation__button">
        <span className="navigation__icon">&nbsp;</span>
    </label>

    <div className="navigation__background">&nbsp;</div>

    <nav className="navigation__nav">
        <ul className="navigation__list">
            <li className="navigation__item" onClick={uncheckNav}><a href="#about_us"  className="navigation__link"><span>01</span>About Us</a></li>
            <li className="navigation__item" onClick={uncheckNav}><a href="#awards" className="navigation__link"><span>02</span>Awards</a></li>
            <li className="navigation__item" onClick={uncheckNav}><a href="#Products" className="navigation__link"><span>03</span>Products</a></li>
            <li className="navigation__item" onClick={uncheckNav}><a href="#contact" className="navigation__link"><span>04</span>Contact Us</a></li>
            </ul>
    </nav>
</div>

<header className="header">
    <div className="header__logo-box">
        <img src="img/logo.png" alt="Logo" className="header__logo"></img>
    </div>

    <div className="header__text-box">
        <h1 className="heading-primary">
            <span className="heading-primary--main">Himalayan Incense</span>
            <span className="heading-primary--sub">Breathe Serenity</span>
        </h1>

        <a href="#Products" className="btn btn--white btn--animated">Products</a>
    </div>
</header>

<main>
    <section className="section-about" id="about_us">
        <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
                About Us
            </h2>
        </div>

        <div className="row">
            <div className="col-1-of-2">
                <h3 className="heading-tertiary u-margin-bottom-small">A Cottage Industry In Bhutan</h3>
                <p className="paragraph">
                    We are an industry categorised as a cottage industry by the Ministry of Economic Affairs,
                    
                </p>

                <h3 className="heading-tertiary u-margin-bottom-small">A bussiness driven by passion and tradition</h3>
                <p className="paragraph">
                    Our bussiness is an age old tradition that we have kept alive because we love doing it
                    and we would not like to see it die
                </p>

                <a href="#Learn_more" className="btn-text">Learn more &rarr;</a>
            </div>
            <div className="col-1-of-2">
                <div className="composition">

                    <img srcSet="img/certificate_dual.jpeg 300w, img/certificate_dual.jpeg 1000w"
                         sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                         alt="Photo 1"
                         className="composition__photo composition__photo--p1"></img>

                    <img srcSet="img/certificate.jpeg 300w, img/certificate.jpeg 1000w"
                         sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                         alt="Photo 2"
                         className="composition__photo composition__photo--p2"
                         src="img/certificate.jpeg"></img>

                    <img srcSet="img/ingredients.jpeg 300w, img/ingredients.jpeg 1000w"
                         sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                         alt="Photo 3"
                         className="composition__photo composition__photo--p3"
                         src="img/ingredients.jpeg"></img>
                </div>
            </div>
        </div>
    </section>

    <section className="section-features" id="awards">
    <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
                Awards
            </h2>
        </div>
        <div className="row">
           

            <div className="col-1-of-4">
                <div className="feature-box">
                    <i className="feature-box__icon icon-basic-book"></i>
                    <h3 className="heading-tertiary u-margin-bottom-small">2021</h3>
                    <p className="feature-box__text">
                    Awarded certificate of product conformity for incense sticks by Bhutan Standards bureau
                    </p>
                </div>
            </div>

            <div className="col-1-of-4">
                <div className="feature-box">
                    <i className="feature-box__icon icon-basic-book"></i>
                    <h3 className="heading-tertiary u-margin-bottom-small">2021</h3>
                     <p className="feature-box__text">
                    Awarded certificate of product conformity for incense powder by Bhutan Standards bureau
                    </p>
                </div>
            </div>

            <div className="col-1-of-4">
                <div className="feature-box">
                    <i className="feature-box__icon icon-basic-cup"></i>
                    <h3 className="heading-tertiary u-margin-bottom-small">2019</h3>
                    <p className="feature-box__text">
                    Awarded Small-scale Entrepruener of the year award by Ministry of Economic affairs, Bhutan
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section className="section-tours" id="Products">
        <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
                Our Products
            </h2>
        </div>

        <div className="row">
            <div className="col-1-of-3">
               <div className="card">
                   <div className="card__side card__side--front">
                        <div className="card__picture card__picture--1">
                            &nbsp;
                        </div>
                        <h4 className="card__heading">
                            <span className="card__heading-span card__heading-span--1">‘Zhi'ney’</span>
                        </h4>
                        <div className="card__details">
                            <ul>
                                <li>Aromatic</li>
                                <li>Revitalises spiritual energy</li>
                                <li>Cleanse inner thoughts</li>
                                <li>Potent to uplift spirits</li>
                                <li>Alleviation of mood</li>
                            </ul>
                        </div>
                   </div>
                   <div className="card__side card__side--back card__side--back-1">
                        <div className="card__cta">
                            <div className="card__price-box">
                                <p className="card__price-only">Only</p>
                                <p className="card__price-value">Nu. 165</p>
                            </div>
                            <a href="#popup" className="btn btn--white">Read More</a>
                        </div>
                    </div>
               </div>
            </div>


            <div className="col-1-of-3">
                <div className="card">
                    <div className="card__side card__side--front">
                        <div className="card__picture card__picture--2">
                            &nbsp;
                        </div>
                        <h4 className="card__heading">
                            <span className="card__heading-span card__heading-span--2">Tsheringma</span>
                        </h4>
                        <div className="card__details">
                            <ul>
                                <li>Combination of 108 medicinal and aromatic plants</li>
                                <li>Burnt in all types of prayers and rituals</li>
                                <li>Purification of your surrounding</li>
                                <li>For a fresh and soothing environment</li>
                            </ul>
                        </div>

                    </div>
                    <div className="card__side card__side--back card__side--back-2">
                        <div className="card__cta">
                            <div className="card__price-box">
                                <p className="card__price-only">Only</p>
                                <p className="card__price-value">Nu. 250</p>
                            </div>
                            <a href="#popup_1" className="btn btn--white">Read More</a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-1-of-3">
                <div className="card">
                    <div className="card__side card__side--front">
                        <div className="card__picture card__picture--3">
                            &nbsp;
                        </div>
                        <h4 className="card__heading">
                            <span className="card__heading-span card__heading-span--3">Dzambala</span>
                        </h4>
                        <div className="card__details">
                            <ul>
                                <li>Manufactured traditionally</li>
                                <li>Pacifies and propitiates all the gods and goddesses of wealth</li>
                                <li>Creates a healthy environment</li>
                                <li>Protects oneself from harmful paranormal beings</li>
                            </ul>
                        </div>

                    </div>
                    <div className="card__side card__side--back card__side--back-3">
                        <div className="card__cta">
                            <div className="card__price-box">
                                <p className="card__price-only">Only</p>
                                <p className="card__price-value">Nu. 120</p>
                            </div>
                            <a href="#popup_2" className="btn btn--white">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="u-center-text u-margin-top-huge">
            <a href="#Products" className="btn btn--green">More Coming Soon!</a>
        </div>
    </section>

    <section className="section-stories">
        <div className="bg-video">
            <video className="bg-video__content" autoPlay muted loop>
                <source src="img/video.mp4" type="video/mp4"></source>
                <source src="img/video.webm" type="video/webm"></source>
                Your browser is not supported!
            </video>
        </div>

        <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
                Hear From Our Customers
            </h2>
        </div>

        <div className="row">
            <div className="story">
                <figure className="story__shape">
                    <img src="img/nat-8.jpg" alt="Person on a tour" className="story__img"></img>
                    <figcaption className="story__caption">Karma Dorji</figcaption>
                </figure>
                <div className="story__text">
                    <h3 className="heading-tertiary u-margin-bottom-small">The Best Aroma</h3>
                    <p>
                       I used the himalayan incense products for my daily offerings and for home cleansing, 
                       it has provided me with a great peace of mind and a great feng shui feeling. i have it on every day
                       and its been a great experience.
                    </p>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="story">
                <figure className="story__shape">
                    <img src="img/nat-9.jpg" alt="Person on a tour" className="story__img"></img>
                    <figcaption className="story__caption">Jack Wilson</figcaption>
                </figure>
                <div className="story__text">
                    <h3 className="heading-tertiary u-margin-bottom-small">Air you can really breathe</h3>
                    <p>
                        My environment just feels so natural and good to breathe, its so nice to have a fresh breath of air while
                        being consumed by the day to day stress and hardship of life and work, i find its presence to be relaxing 
                        and especially good to have a breath of it at the start of the day.         
                    </p>
                </div>
            </div>
        </div>

        <div className="u-center-text u-margin-top-huge">
            <a href="#" className="btn-text">Read all stories &rarr;</a>
        </div>
    </section>

    <section id="contact" className="section-book">
        <div className="row">
            <div className="book">
                <div className="book__form">
                    <htmlform action="#" className="form">
                        <div className="u-margin-bottom-medium">
                            <h2 className="heading-secondary">
                                Reach Out To Us
                            </h2>
                        </div>

                        <div className="form__group">
                            <input type="text" className="form__input" placeholder="Full name" id="name" required></input>
                            <label htmlFor="name" className="form__label">Full name</label>
                        </div>

                        <div className="form__group">
                            <input type="email" className="form__input" placeholder="Email address" id="email" required></input>
                            <label htmlFor="email" className="form__label">Email address</label>
                        </div>

                        <div className="form__group">
                            <input type="text" className="form__input description_box" placeholder="Description" id="email" required></input>
                            <label htmlFor="text" className="form__label">Description</label>
                        </div>


                        <div className="form__group">
                            <button className="btn btn--green">Next step &rarr;</button>
                        </div>
                    </htmlform>
                </div>
            </div>
        </div>
    </section>
</main>

<footer className="footer">
    <div className="footer__logo-box">

        <picture className="footer__logo">
            <source srcSet="img/logo.png 1x, img/logo.png 2x"
                    media="(max-width: 20em)"></source>
            <img srcSet="img/logo.png 1x, img/logo.png 2x" alt="Full logo" src="img/logo.png"></img>
        </picture>


        
    </div>
    <div className="row">
        <div className="col-1-of-2">
            <div className="footer__navigation">
                <ul className="footer__list">
                    <li className="footer__item"><a href="#about_us" className="footer__link">About us</a></li>
                    <li className="footer__item"><a href="#Products" className="footer__link">Products</a></li>
                    <li className="footer__item"><a href="#" className="footer__link"></a></li>
                    <li className="footer__item"><a href="#awards" className="footer__link">Awards</a></li>
                </ul>
            </div>
        </div>
        <div className="col-1-of-2">
            <p className="footer__copyright">
                Built For Himalayan Incense by <a href='https://www.linkedin.com/in/norbu-wangchuk-36924b181/' className='footer__link'>@Norbu Wangchuk</a>.
                <br></br>
                Copyright &copy; Himalayan Incense 
            </p>
        </div>
    </div>
</footer>

<div className="popup" id="popup">
    <div className="popup__content">
        <div className="popup__left">
            <img src="/img/Zhiney_1.jpeg" alt="Tour photo" className="popup__img"></img>
            <img src="/img/Zhiney_2.png" alt="Tour photo" className="popup__img"></img>
        </div>
        <div className="popup__right">
            <a href="#section-tours" className="popup__close">&times;</a>
            <h2 className="heading-secondary u-margin-bottom-small">Product</h2>
            <h3 className="heading-tertiary u-margin-bottom-small">‘Zhi'ney’</h3>
            <p className="popup__text">
            ‘Zhi'ney’ is manufactured by Himalayan Incense production house. 
It is a blend of pure medicinal herbs and precious gem stones that help in calming you down and alleviation of mood. 
Its offering is believed to accumulate merit as it holds the power of cleansing inner thoughts and bringing peace to one’s mind. 
Its distinctive aroma has the potent to uplift spirits, refresh and reverberate wellbeing. It is also believed to be capable of liberating 
oneself from suffering of various diseases in the present life and attain a higher realm for next life. <br></br>
༆ ཞི་གནས་ ཟེར་བའི་བདུག་སྤོས་འདི་ ཧི་མ་ལ་ཡཱན་སྤོས་བཟོ་ཁང་གིས་ དྲི་བཟང་པོ་རང་བཞིན་གྱིས་ཡོད་པའི་ སྔོ་སྨན་
རྩི་ཤིང་དང་རིན་པོ་ཆེའི་བྱེ་བྲག་མང་རབས་ཅིག་ གཅིག་ཁར་སྦྱར་ཏེ་བཟོ་བཟོཝ་ཨིན། བདུག་སྤོས་འདི་ སྔར་སྲོལ་འབྲུག་པའི་
སྤོས་བཟོ་ལམ་ལུགས་དང་འཁྲིལ་ཏེ་བཟོ་ཡོདཔ་ད།  བདུག་སྤོས་དྲིའི་མཆོད་པ་གིས་ རང་གི་ཚོར་ཉམས་དང་སེམས་ཁམས་ཞི་

            </p>
            <a href="https://www.zala.bt/product/tri-zang-zhi-ney-sang-incense-powder-250g" className="btn btn--green">Shop On Zala</a>
        </div>
    </div>
</div>
<div className="popup" id="popup_1">
    <div className="popup__content">
        <div className="popup__left">
            <img src="/img/Tsheringma_1.JPG" alt="Tour photo" className="popup__img"></img>
            <img src="/img/Tsheringma_2.JPG" alt="Tour photo" className="popup__img"></img>
        </div>
        <div className="popup__right">
            <a href="#section-tours" className="popup__close">&times;</a>
            <h2 className="heading-secondary u-margin-bottom-small">Product</h2>
            <h3 className="heading-tertiary u-margin-bottom-small">Tsheringma</h3>
            <p className="popup__text">
            A combination of 108 medicinal and aromatic plants from the pristine and tranquil landscape of the Himalayas, 
            this is a legendary formulation believed to be formulated by Guru Padmasambhava. This profusely aromatic incense powder 
            can be burnt in all types of prayers and rituals as an offering to the gods and deities of the three realms. It can also 
            be used for the purification of your surrounding and to appease the wandering spirits or just for a fresh and soothing environment around you.

            </p>
            <a href="https://www.zala.bt/product/tsheringma-men-sang-ja-tsa-gyed-incense-powder-250g" className="btn btn--green">Shop On Zala</a>
        </div>
    </div>
</div>
<div className="popup" id="popup_2">
    <div className="popup__content">
        <div className="popup__left">
            <img src="/img/Dzambala_1.JPG" alt="Tour photo" className="popup__img"></img>
            <img src="/img/Dzambala_2.JPG" alt="Tour photo" className="popup__img"></img>
        </div>
        <div className="popup__right">
            <a href="#section-tours" className="popup__close">&times;</a>
            <h2 className="heading-secondary u-margin-bottom-small">Product</h2>
            <h3 className="heading-tertiary u-margin-bottom-small">Dzambala</h3>
            <p className="popup__text">
            Prosperity Incense has been prepared as per the formulation described in ancient text. 
            It is compounded with highly aromatic and medicinal plants that has been manufactured traditionally under strict quality compliance. 
            Generally, burning this incense produces appealing smoke that pacifies and propitiates all the gods and goddesses of wealth and other 
            protecting deities which eventually results in the accumulation of wealth and prosperity. Besides, it creates a healthy environment and 
            protect oneself from harmful paranormal beings.

            </p>
            <a href="#" className="btn btn--green">Coming Soon!</a>
        </div>
    </div>
</div>
<div className="popup" id="Learn_more">
    <div className="popup__content">
            <a href="#section-tours" className="popup__close">&times;</a>
            <h2 className="heading-secondary u-margin-bottom-small">Our Story</h2>
            <h3 className="heading-tertiary u-margin-bottom-small">The Himalayan Story</h3>
            <p className="popup__text">
            Himalayan Incense is one of the largest hand-made manufacturers of incense products in Bhutan. It started off as a contract manufacturer of Menjong Sorig pharmaceuticals under the department of Traditional Medicine Services, Ministry of Health (MoH), Thimphu, in June 2015. It manages two brands, namely Tsheringma and Tri-zang. The company has won the title of small-scale entrepreneur of the year in 2019 and is the very first of its kind to receive a product conformity certificate from Bhutan Standards Bureau in July 2021, i.e., valid till July 2023.
The formulation of our products has been derived from Buddhist relic scriptures. Our products are famous for their incorporation of 108 raw materials. These raw materials are collected from the high and low altitudes of Bhutan. It is inclusive of gold, silver, agate, and pearls which contribute to an exotic experience. Starting with the collection of raw materials, processing, and packaging of the products, the company has helped in employment and promoted self-sustenance of its employees.
            </p>
        </div>
    </div>
</div>
  );
}

export default App;